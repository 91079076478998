<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>优惠券</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="优惠券名称">
                                <el-input v-model="search.coupon_title"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                            @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                            @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="is_auth('coupon.library.isadd')" icon="el-icon-plus" size="medium"
                            type="primary" @click="toadd()">添加
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
                <el-table-column prop="coupon_title" label="优惠券名称">
                </el-table-column>
                <el-table-column prop="cover" label="优惠券封面" width="100">
                    <template slot-scope="scope">
                        <viewer v-if="!is_empty(scope.row.cover)">
                            <el-avatar shape="square" :size="22" :src="scope.row.cover"></el-avatar>
                        </viewer>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column prop="state" label="状态" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state === 1">正常</el-tag>
                        <el-tag size="mini" v-if="scope.row.state === 2" type="danger">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="range_type" label="适用范围" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.range_type === 1">全平台</el-tag>
                        <el-tag size="mini" v-if="scope.row.range_type === 2" type="success">
                            {{ scope.row.company_name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="store_range" label="门店范围" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.store_range === 1">所有门店</el-tag>
                        <el-tag size="mini" v-if="scope.row.store_range === 2" type="success">指定门店</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="lesson_range" label="课程范围" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.lesson_range === 1">所有课程</el-tag>
                        <el-tag size="mini" v-if="scope.row.lesson_range === 2" type="success">指定课程</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="full_money" label="满减限制">
                    <template slot-scope="scope">
                        <span>满￥{{ scope.row.full_money }}可用</span>
                    </template>
                </el-table-column>
                <el-table-column prop="coupon_money" label="抵扣金额">
                    <template slot-scope="scope">
                        <span>￥{{ scope.row.coupon_money }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="validity_time" label="有效期">
                    <template slot-scope="scope">
                        <span v-if="scope.row.validity_time === 0">无限制</span>
                        <span v-else>{{ scope.row.validity_time }}天</span>
                    </template>
                </el-table-column>
                <el-table-column prop="use" label="使用人次" width="100">
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="320">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('coupon.library.getinfo')" @click="tosee(scope.row.coupon_uuid)"
                            size="mini">查看
                        </el-button>
                        <div style="display:inline-block;margin:0 10px" v-if="is_auth('coupon.library.postdueswitch')">
                            <el-button v-if="scope.row.due_date_switch === 0" @click="openRemindDialog(scope.row)"
                                type="primary" size="mini">设置提醒
                            </el-button>
                            <el-button
                                v-else-if="scope.row.due_date_switch === 1 && is_auth('coupon.library.postclosedueswitch')"
                                type="warning" @click="closeRemind(scope.row)" size="mini">取消提醒
                            </el-button>
                        </div>
                        <el-button v-if="is_auth('coupon.library.isenable') && scope.row.state === 2"
                            @click="operation_tip(scope.row.coupon_uuid, scope.row.coupon_title, 'isenable')"
                            type="primary" size="mini">启用
                        </el-button>
                        <el-button v-if="is_auth('coupon.library.isdisable') && scope.row.state === 1"
                            @click="operation_tip(scope.row.coupon_uuid, scope.row.coupon_title, 'isdisable')"
                            type="danger" size="mini">禁用
                        </el-button>
                        <el-button v-if="is_auth('coupon.library.isdel')"
                            @click="operation_tip(scope.row.coupon_uuid, scope.row.coupon_title, 'isdel')" type="danger"
                            size="mini">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
                layout="prev, pager, next, total" :current-page.sync="page" :total="count">
            </el-pagination>

        </div>
        <!-- 设置提醒弹窗 -->
        <el-dialog v-loading="loading" title="设置提醒" :visible.sync="isRemindShow" width="780px"
            :close-on-press-escape="false" :close-on-click-modal="false" @close="clearState">
            <el-row>
                <el-form size="medium" ref="form" :label-width="this.env.label_width">
                    <!-- 距离到期日设置 -->
                    <el-col :span="24">
                        <el-form-item label="距离到期日">
                            <el-input  v-model="remindData.due_date_num" type="number" maxlength="20" show-word-limit
                                placeholder="距离优惠券到期日的天数，允许负数，例：0表示到期当天，1表示提前1天，-1表示过期1天" rows="3" />
                        </el-form-item>
                    </el-col>
                    <!-- 提醒类型 -->
                    <el-col :span="24">
                        <el-form-item label="提醒类型">
                            <el-switch v-model="remindData.wx" active-color="#13ce66" inactive-color="#999"
                                active-text="小程序信息">
                            </el-switch>
                            <el-switch style="margin-left:24px" v-model="remindData.sms" active-color="#13ce66"
                                inactive-color="#999" active-text="短信信息">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                    <!-- 小程序消息模板 -->
                    <el-col v-if="remindData.wx" :span="24">
                        <el-form-item label="小程序消息模板">
                            <el-select v-model="wxTemplateName" style="width: 100%" placeholder="请选择小程序消息模板" filterable
                                @change="changeWxTemplate">
                                <el-option v-for="item in wxTemplateList" :key="item.key_id" :label="item.name"
                                    :value="item.key_id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- 短信消息模板 -->
                    <el-col v-if="remindData.sms" :span="24">
                        <el-form-item label="短信消息模板">
                            <el-select v-model="smsTemplateName" style="width: 100%" placeholder="请选择短信消息模板" filterable
                                @change="changeSmsTemplate">
                                <el-option v-for="item in smsTemplateList" :key="item.key_id" :label="item.name"
                                    :value="item.key_id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
            <el-row style="margin: 10px 0px">
                <el-button v-if="is_auth('coupon.library.postdueswitch')" size="medium" type="primary"
                    @click="submitForm">
                    确 定</el-button>
                <el-button size="medium" @click="clearState">取 消</el-button>
            </el-row>
        </el-dialog>

    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '优惠券',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
            wxTemplateName: "", // 微信模板名称
            smsTemplateName: "", // 短信模板名称
            wxTemplateId: "", // 选择的微信模板id
            smsTemplateId: "", // 选择的短信模板id
            isRemindShow: false, // 设置提醒弹窗是否显示
            chooseCoupon: {}, // 当前选中的优惠券
            wxTemplateList: [], // 微信模板列表数据
            smsTemplateList: [], // 短信模板列表数据
            remindData: { wx: false, sms: false },
        }
    },
    // 创建
    created() {
        this.init()
        this.getTemplateData();
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                coupon_title: '',
            }
            this.searchtime = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "coupon.library.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 获取可用发送模板列表
        getTemplateData() {
            this.Tool.post_data("oss", {
                api_name: "usermsgtpl.wxtemplate.gettpllist",
                token: this.Tool.get_l_cache("token"),
            }, (res) => {
                this.loading = false;
                if (res.code === 0) {
                    this.wxTemplateList = res.data;
                }
            });
            this.Tool.post_data("oss", {
                api_name: "usermsgtpl.smstemplate.getsmslist",
                token: this.Tool.get_l_cache("token"),
            }, (res) => {
                this.loading = false;
                if (res.code === 0) {
                    this.smsTemplateList = res.data;
                }
            });
        },
        // 操作提示
        operation_tip(coupon_uuid = '', coupon_title = '', operation = '') {

            let tip = ''
            let options = {}
            // 启用
            if (operation === 'isenable') {
                tip = '启用【' + coupon_title + '】？'
            }
            // 禁用
            if (operation === 'isdisable') {
                tip = '禁用【' + coupon_title + '】？'
            }
            // 删除
            if (operation === 'isdel') {
                tip = '删除【' + coupon_title + '】？'
                options = { type: 'error' }
            }
            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                .then(() => {
                    this.isoperation(coupon_uuid, operation)
                })
                .catch(() => {
                });
        },
        // 操作
        isoperation(coupon_uuid = '', operation = '') {
            let postdata = {
                api_name: "coupon.library." + operation,
                token: this.Tool.get_l_cache('token'),
                coupon_uuid,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 添加
        toadd() {
            this.$router.push({ path: '/coupon/library/add' })
        },
        // 查看
        tosee(coupon_uuid) {
            this.$router.push({ path: '/coupon/library/info', query: { coupon_uuid } })
        },
        // 打开设置提醒弹窗
        openRemindDialog(item) {
            this.isRemindShow = true;
            this.chooseCoupon = item;
        },
        // 关闭设置提醒弹窗，并清除数据
        clearState() {
            this.isRemindShow = false;
            this.chooseCoupon = {};
            this.remindData = { wx: false, sms: false };
            this.wxTemplateName = "";
            this.smsTemplateName = "";
            this.wxTemplateId = "";
            this.smsTemplateId = "";
        },
        changeWxTemplate(id) {
            this.wxTemplateId = id;
        },
        changeSmsTemplate(id) {
            this.smsTemplateId = id;
        },
        // 设置优惠券提醒
        submitForm() {
            const { wxTemplateId, smsTemplateId, remindData, chooseCoupon } = this;
            const { due_date_num, sms, wx } = remindData;
            const { coupon_uuid, validity_time } = chooseCoupon;
            const postdata = {
                api_name: "coupon.library.postdueswitch",
                token: this.Tool.get_l_cache('token'),
                coupon_uuid,
                due_date_num,
                reminder_data: {
                    "sms": "",
                    "applets": ""
                }
            }
            if (sms) {
                postdata["reminder_data"]["sms"] = smsTemplateId;
            }
            if (wx) {
                postdata["reminder_data"]["applets"] = wxTemplateId;
            }
            if (due_date_num) {
                if (parseInt(due_date_num) > validity_time) {
                    this.$message.warning(`设置的日期不可大于优惠券有效期，当前优惠券有效期为${validity_time}天`)
                } else {
                    this.loading = true;
                    this.Tool.post_data('oss', postdata, (json) => {
                        this.loading = false
                        if (json.code === 0) {
                            this.$message({
                                message: '设置成功', type: 'success',
                                duration: this.env.message_duration,
                                onClose: () => {
                                    this.clearState();
                                    this.getlist()    // 刷新列表(重新搜索)
                                }
                            });
                        } else {
                            this.Tool.errormes(json)
                        }
                    })
                }
            } else {
                this.$message.warning("设置的时间不可为空");
            }
        },
        // 关闭优惠券设置提醒
        closeRemind(item) {
            const h = this.$createElement;
            const { applets_name, sms_name } = item.reminder_data;
            this.$msgbox({
                title: '关闭提醒',
                message: h('p', null, [
                    h('p', { style: 'margin-bottom: 10px' }, `设置天数：${item.due_date_num}天`),
                    applets_name ? h('p', { style: 'margin-bottom: 10px' }, `微信小程序模板名称：${applets_name}`) : "",
                    sms_name ? h('p', { style: 'margin-bottom: 10px' }, `短信模板名称：${sms_name}`) : "",
                    h('span', null, `是否确认关闭`),
                    h('span', { style: 'color: red' }, ` ${item.coupon_title} `),
                    h('span', null, `优惠券的设置提醒`),
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        this.closeCouponRemind(item.coupon_uuid);
                        done();
                    } else {
                        done();
                    }
                }
            })
        },
        // 关闭优惠券接口
        closeCouponRemind(coupon_uuid) {
            const postdata = {
                api_name: "coupon.library.postclosedueswitch",
                token: this.Tool.get_l_cache('token'),
                coupon_uuid: coupon_uuid,
            }
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '关闭成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>>.el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}
</style>
